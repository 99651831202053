<template>
  <div class="boutique-wrap main-wrapper">
    <!-- 精品账号 -->
    <div class="account-box">
      <div class="top">
        <div class="title">
          <div class="label">PUBG优选</div>
          <div class="text">
            由于PUBG优选账号数量有限，需要满足近7天绝地求生游戏时长大于{{
              timer
            }}小时才可体验PUBG优选账号
          </div>
        </div>
        <!-- <img src="@/assets/img/time.png" alt /> -->
      </div>

      <div class="item-box">
        <div class="title">
          <div class="label">热门系列:</div>
          <div class="search-box">
            <div class="checkbox-box">
              <span v-for="(item, index) in spanList" :key="index" :class="spanIndex == index ? 'spanActive' : ''"
                @click="handleChangeSpan(item, index)">{{ item }}</span>
            </div>
            <div class="input-box">
              <el-input :placeholder="placeText" v-model="title" size="small" @keydown.enter.native="handleSearch"
                @focus="handleFocus" @blur="handleBlur"></el-input>
              <el-button size="small" type="primary" @click="handleSearch" icon="el-icon-search"></el-button>
            </div>
          </div>
        </div>

        <div class="title">
          <div class="label">高级筛选:</div>
          <div class="search-box span-box">
            <div class="checkbox-box">
              <el-checkbox class="checked" label="可玩账号" v-model="checked" @change="handleChecked"></el-checkbox>
            </div>
            <div class="select-box">
              <el-popover placement="bottom" trigger="hover" v-for="(item, index) in attrList" :key="index"
                @show="showPopover(index)" @hide="hidePopover">
                <div class="attribute-box">
                  <span v-for="(i, o) in item.childern" :class="[
                    'attribute',
                    childerActive == o ? 'childerActive' : '',
                  ]" :key="o" @click="selectItem(i, o)">{{ i }}</span>
                  <!-- || title == i  -->
                </div>
                <div slot="reference" :class="['btn', activeIndex == index ? 'active' : '']">{{ item.name }}</div>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 综合排序 -->
    <div class="sort-box">
      <div class="item-box">
        <div class="item" v-for="(item, index) in accountList" :key="index" @click="toDetail(item)">
          <div class="content-box">
            <p class="ellipsis2">
              <img class="icon" src="@/assets/img/pubg.png" alt />
              <span v-html="item.Title"></span>
            </p>
          </div>
          <div class="btn-box">
            <el-button v-if="item.Status !== '4'" @click="toDetail(item)">开始玩游戏</el-button>
            <el-button class="occupy" v-else>占用中，请稍后</el-button>
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <div class="pagination-box" v-if="accountList.length">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="pageIndex" :page-size="pageSize" layout="prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>

      <div v-if="nodata" class="noData">
        <div class="no-data">
          <img src="@/assets/img/noData.png" alt />
          <p>抱歉，没有找到你要的账号</p>
        </div>
      </div>
    </div>  </div>
</template>

<script>
import { GetGameProducts, GetNavigationList, GetAccounts } from "@/api";
import { mapGetters } from "vuex";
import cMethods from "@/util/cMethods.js";

export default {
  name: "Home",
  components: {},
  computed: {
    ...mapGetters(["isLogin", "netBarId", "netBarVIP", "userInfo"]),
  },
  data() {
    return {
      active: null,
      dialogVisible: false,
      // goodsList: [],
      accountList: [],
      productId: "",
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      netbarId: this.netBarId,
      internalTest: null,
      labels: "",
      title: "",
      checkList: [],
      checked: true,
      timer: 0,
      placeText: "请输入您要搜索的内容",
      nodata: false,
      activeIndex: null,
      childerActive: null,
      spanIndex: null,
      spanList: [
        "小青龙",
        "北极熊",
        "5周年纪念",
        "晚秋",
        "疯狂收割者",
        "枪儿响叮当",
        "雪国风光"
      ],
      attrList: [
        {
          name: "突击步枪",
          childern: [
            "山花烂漫-GROZA",
            "分形几何-G36C",
            "镀银-AUG",
            "鎏金王朝-QBZ",
            "疯狂收割者-SCAR-L",
            "湮灭-BERYL M762",
            "愉悦的绿松色-M16A4",
            "雪国风光-M416",
            "无尽迷宫-MK47 MUTANT",
            "鲨鱼咬痕-M16A4",
            "竞速之道-AKM",
            "三重彩-SCAR-L",
            "魔女之力-BERYL M762",
            "分秒必争-QBZ",
          ],
        },
        {
          name: "狙击枪",
          childern: [
            "鲜花之力-AWM",
            "数码沙漠迷彩-WIN94",
            "爬行者之印-莫辛纳甘步枪",
            "镀金枪儿响叮当-KAR98K",
            "愉悦的绿松色-KAR98K",
            "镀金-WIN94",
            "幸运骑士-M24",
            "疯狂收割者-M24",
            "恶战-莫辛纳甘步枪",
            "魔女之力-AWM",
          ],
        },
        {
          name: "射手步枪",
          childern: [
            "漫彩四溢-SLR",
            "猛虎猎手-SLR",
            "锯齿收割者-SLR",
            "百变小丑-SLR",
            "疯狂收割者-QBU",
            "鎏金王朝-QBU",
            "花呢控-VSS",
            "斑点狗-VSS",
            "漫彩四溢-SKS",
            "幸运骑士-SKS",
            "林书豪-SLR",
            "黄道猎手-SKS",
            "铃儿响叮当-MINI14",
            // "破碎元素-MINI14",
            "数码沙漠迷彩-MINI14",
            "拼图-MINI14",
          ],
        },
        {
          name: "冲锋枪",
          childern: [
            "拼图-汤姆逊",
            "竞速之道-P90",
            "疯狂收割者-MP5K",
            "灰色系-MP5K",
            "三重彩-MICRO UZI",
            "钴蓝呼号-MICRO UZI",
            "竞速之道-UMP45",
            "海狼鱼-VECTOR",
            "雪国风光-汤姆逊",
            "愉悦的绿松色-汤姆逊",
            "辉煌-UMP45",
            "游猎之旅-UMP45",
            "魔女之力-VECTOR",
          ],
        },
        {
          name: "装备",
          childern: [
            "传奇高手旅行背包",
            "治安队-头盔",
            "孙兴慜-头盔",
            "生存者车手-头盔",
            // "赛季11-15竞技降落伞",
            "萌虎下山-头盔",
            "百变小丑-头盔",
            "一夜好眠背包",
            "BLACKPINK背包",
            "竞技降落伞",
            "马戏棚背包",
            "治安队背包",
            "寻腥猎犬背包",
          ],
        },
        {
          name: "套装",
          childern: [
            "缤纷圣诞",
            "潜水大师",
            // "林书豪服装",
            "林书豪",
            "嬉皮风服饰",
            "杂技熊玩偶服",
            "慵懒假日",
            "准备就绪",
            "传奇拉拉队",
            "暖心",
            "龙纹旗袍",
            "学院派",
            "尽染樱色女款校服",
            "马戏团之星演出服",
            "龙纹武术",
          ],
        },
        {
          name: "上衣",
          childern: [
            "狼王毛衣",
            "小兔航空航天局",
            "暖心毛衣",
            "花衬衫",
            "小恐龙露脐装",
            "林书豪的羽绒服",
            "圣诞老人自拍用",
            "嬉皮风系带背心",
            "轰炸机夹克",
            "缤纷圣诞毛衣",
            "纯净连帽卫衣",
            "公主的力量背心",
            "4周年纪念小队",
            "作战小队短款",
            "棉花糖T恤",
          ],
        },
        {
          name: "腿部",
          childern: [
            "小兔航空航天局短裤",
            "林书豪的篮球裤",
            "缤纷圣诞牛仔裤",
            "车手裤",
            "年末问候洋裙",
            "传奇拉拉队短裙",
            "寻路者短裙",
            "学院派百褶裙",
            // "暖心长裤",
            "长裤",
            "圣诞老人自拍用",
            // "慵懒假日长裤",
            "短靴"
            // "嬉皮风喇叭裤",
          ],
        },
      ],
    };
  },
  mounted() {
    if (this.netBarId) {
      // this._GetGameProducts();
      this._GetAccounts();
    }
    this._GetNavigationList();

    _czc.push(["_trackEvent", "PUBG优选", "列表页面", "展现量"]);
  },
  methods: {
    handleChangeSpan(item, index) {
      if (this.spanIndex == index) {
        this.title = "";
        this.spanIndex = null;
        this.activeIndex = null;
        this.childerActive = null;
        this.pageIndex = 1;
        this._GetAccounts();
      } else {
        this.title = item;
        this.spanIndex = index;
        this.activeIndex = null;
        this.childerActive = null;
        this.pageIndex = 1;
        this._GetAccounts((resultsList) => {
          // 关键字变红色
          this.changeColor(resultsList);
        });
      }
    },
    handleFocus() {
      this.placeText = "";
    },
    handleBlur() {
      this.placeText = "请输入您要搜索的内容";
    },
    handleChecked(e) {
      e ? (this.state = 1) : (this.state = "");
      this.pageIndex = 1;
      this._GetAccounts();
    },
    handleCheckedChange(e) {
      if (e.length) {
        // this.checkList.unshift();
        // console.log(this.checkList.shift())
        if (this.checkList.length > 1) {
          this.checkList.shift();
        }
        this.labels = e.toString();
      } else {
        this.labels = "";
      }
      this.title = "";
      this.activeIndex = null;
      this.childerActive = null;
      this.pageIndex = 1;
      this._GetAccounts();
    },
    // async _GetGameProducts() {
    //   let params = {
    //     netbarId: this.netBarId,
    //   };
    //   let res = await GetGameProducts(params);
    //   this.goodsList = res.Object;
    // },
    async _GetAccounts(cb) {
      let params = {
        productId: this.productId,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        netbarId: this.netBarId,
        labels: this.labels,
        state: this.checked ? "1" : "",
        title: this.title,
      };
      let res = await GetAccounts(params);
      this.accountList = res.Items;
      this.total = res.Total;
      if (this.accountList.length == 0) {
        this.nodata = true;
      } else {
        this.nodata = false;
      }
      // 处理搜索关键字变红
      cb && cb(res.Items);
    },
    toDetail(item) {
      sessionStorage.setItem("detail", JSON.stringify(item));
      this.$router.push({
        path: "/boutiqueDetail",
      });
    },
    // 一页展示多少条
    handleSizeChange(val) {
      this.pageIndex = 1;
      this.pageSize = val;
      this._GetAccounts((resultsList) => {
        // 关键字变红色
        this.changeColor(resultsList);
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.pageIndex = val;
      this._GetAccounts((resultsList) => {
        // 关键字变红色
        this.changeColor(resultsList);
      });
    },
    // 获取网吧是否配置内测账号
    async _GetNavigationList() {
      let params = {
        type: 7,
      };
      let res = await GetNavigationList(params);
      if (res.StatusCode == 200) {
        this.internalTest = res.Object;
        this.timer = res.Object[0].Url;
      }
    },
    handleChange(index) {
      if (index !== this.active) {
        this.active = index;

        if (this.active == 99) {
          this.productId = "nc";
        } else {
          this.productId = this.goodsList[index].ProductID;
        }
      } else {
        this.active = null;
        this.productId = "";
      }

      this.pageIndex = 1;
      this._GetAccounts();
    },
    handleSearch() {
      this.activeIndex = null;
      this.childerActive = null;
      this.pageIndex = 1;
      this._GetAccounts((resultsList) => {
        // 关键字变红色
        this.changeColor(resultsList);
      });
    },
    selectItem(str, index) {
      this.spanIndex = null;
      this.labels = "";
      this.checkList = [];
      this.title = str;
      this.childerActive = index;
      this.handleSearch();
    },
    changeColor(resultsList) {
      resultsList.map((item, index) => {
        if (this.title) {
          // 匹配关键字正则
          let replaceReg = new RegExp(this.title, "g");
          // 高亮替换v-html值
          let replaceString =
            '<span class="search-text">' + this.title + "</span>";
          if (resultsList[index].Title) {
            resultsList[index].Title = item.Title.replace(
              replaceReg,
              replaceString
            );

            // console.log(
            //   "总长度:" + resultsList[index].Title.length,
            //   "出现在位置" + resultsList[index].Title.indexOf(this.title),
            //   "最后; " +
            //     resultsList[index].Title.lastIndexOf(
            //       "；",
            //       resultsList[index].Title.indexOf(this.title)
            //     )
            // );

            let str = resultsList[index].Title.slice(
              resultsList[index].Title.lastIndexOf(
                "；",
                resultsList[index].Title.indexOf(this.title)
              ) + 1,
              resultsList[index].Title.indexOf(
                "；",
                resultsList[index].Title.indexOf(this.title)
              ) + 1
            );

            resultsList[index].Title =
              str + resultsList[index].Title.replace(str, "");
          }
        }
      });
      this.listData = [];
      this.listData = resultsList;
    },
    showPopover(index) {
      this.activeIndex = index;
    },
    hidePopover() {
      this.childerActive = null;
    },
    inputHandleChange(e) {
      this.title = e;
      this.pageIndex = 1;
      this._GetAccounts((resultsList) => {
        this.activeIndex = null;
        this.childerActive = null;
        // 关键字变红色
        this.changeColor(resultsList);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.boutique-wrap {
  .account-box {
    padding: 20px;
    @include item_bg_col();
    @include font_col3();

    .top {
      display: flex;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;

        &::before {
          height: 18px;
          content: "";
          border-left: 4px solid #ff6c6c;
          padding-left: 10px;
        }

        .label {
          font-size: 18px;
          font-weight: bold;
        }

        .text {
          font-size: 14px;
          font-weight: normal;
          margin-left: 30px;
        }
      }
    }

    .item-box {
      margin-top: 24px;
      border-top: 1px solid;
      @include bd_color();

      .title {
        margin-top: 10px;
        @include font_col3();
        @include item_bg_col();

        display: flex;
        // justify-content: space-between;
        align-items: center;

        .label {
          font-size: 18px;
          font-weight: bold;
        }

        .search-box {
          display: flex;
          flex: 1;
          justify-content: space-between;

          &.span-box {
            justify-content: flex-start;
            align-items: center;

            .btn {
              margin: 0 10px;
              padding: 8px 20px;
              border-radius: 4px;
              @include item_bd_color();

              &:hover {
                cursor: pointer;
                color: $red_col;
                border-color: $red_col;
              }

              &.active {
                color: $red_col;
                border-color: $red_col;
              }
            }
          }

          .checkbox-box {
            display: flex;
            align-items: center;
            margin-left: 20px;

            // .checked {
            //   margin-right: 10px;
            // }
            span {
              padding: 10px 15px;
              border-radius: 4px;
              margin-right: 10px;

              &:hover {
                cursor: pointer;
                color: $white-col;
                background: $red-col;
              }
            }

            .spanActive {
              color: $white-col;
              background: $red-col;
            }
          }

          .input-box {
            // margin-left: 140px;
            display: flex;

            ::v-deep .el-input__inner {
              height: 40px;
              border: none;
              @include font_col6();
              @include input_bg_color();
              border-radius: 4px 0 0 4px;
            }

            .el-button {
              color: #fff;
              border: none;
              border-radius: 0 4px 4px 0;
            }
          }

          .select-box {
            display: flex;
          }
        }
      }

      .item {
        height: 64px;
        padding: 0 10px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        @include item_bd_color();
        border-radius: 2px;
        position: relative;

        img {
          width: 60px;
          height: 50px;
        }

        .name {
          margin-left: 10px;
          line-height: 20px;
        }

        .icon {
          width: 146px;
          height: 35px;
          position: absolute;
          top: -5px;
          right: -5px;
        }

        &:hover {
          cursor: pointer;
          border: 1px solid $red-col;
        }

        &.active {
          border: 1px solid $red-col;
        }
      }
    }
  }

  .sort-box {
    margin-top: 16px;

    .item-box {
      .item {
        padding: 20px;
        @include font_col6();
        @include item_bg_col();
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;

        &:hover {
          cursor: pointer;
          // transform: translateY(-5px);
          @include item_bg_hover();
        }

        .content-box {
          margin-right: 40px;
          font-size: 16px;
          font-weight: 500;
          @include font_col6();
          line-height: 25px;
          display: flex;
          flex: 1;
          flex-direction: column;

          .icon {
            margin-right: 10px;
            vertical-align: middle;
          }

          p {
            word-break: break-all;
          }

          .ellipsis2 {
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .tags {
            margin-top: 10px;
            display: flex;
            align-items: center;

            span {
              padding: 0 10px;
              height: 28px;
              text-align: center;
              @include tag_item_bg_col();
              @include tag_item_col();
              border-radius: 14px;
              font-size: 14px;
              line-height: 28px;
              margin-right: 10px;
            }
          }
        }

        .btn-box {
          .el-button {
            width: 128px;
            font-size: 15px;
            // @include btn_bg_col();
            // @include btn_col();
            background: transparent;
            color: $red-col;
            border: none;
            padding: 12px 10px;
            border: 1px solid $red-col;

            &:hover {
              color: $white-col;
              background: $red-col;
            }
          }

          .occupy {
            color: $white-col;
            cursor: default;
            border: 1px solid #bcbcbc;
            background: #bcbcbc;

            &:hover {
              background: #bcbcbc;
            }
          }
        }
      }
    }

    .pagination-box {
      margin-top: 20px;
      text-align: center;
    }

    .no-data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 484px;
      border-radius: 8px;
      text-align: center;
      @include item_bg_col();

      p {
        font-size: 16px;
        margin-top: 30px;
        @include font_col6();
      }
    }
  }
}
</style>


<style lang="scss">
.el-checkbox {
  margin-right: 10px;
}

.search-text {
  color: red;
}

.attribute-box {
  max-width: 500px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  @include item_bg_col();

  .attribute {
    padding: 5px 10px;
    margin: 5px;
    @include item_bd_color();

    &:hover {
      cursor: pointer;
      color: $red_col;
      border-color: $red_col;
    }

    &.childerActive {
      color: $red_col;
      border-color: $red_col;
    }
  }
}
</style>